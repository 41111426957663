











import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class QRCodes extends Vue {
	data(): any {
		return {
			codes: ['hhfhfdgdg', 'jkljlhjkl']
		};
	}

	private chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';

	async mounted() {
		let count: number = 150;
		if (this.$route.query.count) {
			count = Number(this.$route.query.count);
		}

		let start = 10000;
		if (this.$route.query.start) {
			start = Number(this.$route.query.start);
		}

		let prefix = 'AA';
		if (typeof this.$route.query.prefix === 'string') {
			prefix = this.$route.query.prefix;
		}

		let codes: string[] = [];
		for (let i = start; i < start + count; i++) {
			codes.push(prefix + '-' + i);
		}
		this.$data.codes = codes;
	}

	// gen (n: number, current: string = ""): string {
	//  if (n == 0)
	//      return current;
	//  return this.gen(Math.floor(n / this.chars.length),  this.chars.charAt(n % this.chars.length ) + current)
	// }

	// invgen (str : string) : number {
	// 	return 1000000;
	// }
}
